import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes, useNavigate } from "react-router-dom";
import AppProvider from "./context";

import { appColor } from "./constants/Colors.js";
import Dashboard from "./containers/app/dashboard";
import Home from "./containers/app/home/index.js";
import MyAccount from "./containers/app/myAccount/index.js";
import Privacy from "./containers/app/privacyAndTerms/Privacy.js";
import Terms from "./containers/app/privacyAndTerms/Terms.js";
import Shop from "./containers/app/shop";
import ForgotPasswordPage from "./containers/auth/ForgotPasswordPage.js";
import LoginPage from "./containers/auth/LoginPage.js";
import SignupPage from "./containers/auth/SignupPage.js";
import VerifyCode from "./containers/auth/VerifyForgotCode.js";

//youtube giveaway
import Participants from "./containers/app/youtubeGiveaway/channelSection.js/Participants";
import ChannelSection from "./containers/app/youtubeGiveaway/channelSection.js/index.js";
import Certitficate from "./containers/app/youtubeGiveaway/congratulations/Certitficate.js";
import Winners from "./containers/app/youtubeGiveaway/congratulations/Winners";
import Congratulations from "./containers/app/youtubeGiveaway/congratulations/index";
import YoutubeGiveAway from "./containers/app/youtubeGiveaway/home/index.js";

//twitter Giveaway
import TwitterGiveAway from "./containers/app/twitterGiveaway/home/index.js";
import TwitterCerticate from "./containers/app/twitterGiveaway/postDetails/CertificateWinners.js";
import PostStatus from "./containers/app/twitterGiveaway/postDetails/PostStatus.js";
import ReplyFilter from "./containers/app/twitterGiveaway/postDetails/ReplyFilter.js";
import TwitterWinners from "./containers/app/twitterGiveaway/postDetails/Winners.js";
import { useEffect } from "react";
import InstagramGiveaway from "./containers/app/instagramGiveaway/home/index.js";
import AllUserPosts from "./containers/app/instagramGiveaway/postDetails/allUserPosts.js";
import SinglePost from "./containers/app/instagramGiveaway/postDetails/singlePost.js";
import FilterInstaComments from "./containers/app/instagramGiveaway/postDetails/filterComments.js";
import InstaPostDetail from "./containers/app/instagramGiveaway/postDetails/index.js";
import InstaAccounts from "./containers/app/instagramGiveaway/postDetails/instaAccounts.js";
import PagesPosts from "./containers/app/instagramGiveaway/postDetails/pagesPost.js";
import InstagramWinners from "./containers/app/instagramGiveaway/postDetails/Winners.js";
import InstagramCerticate from "./containers/app/instagramGiveaway/postDetails/certificateWinners.js";
import TikTokGivaway from "./containers/app/tiktokGiveaway/home/index.js";
import TikTokPostStats from "./containers/app/tiktokGiveaway/postDetail/index.js";
import TikTokReplyFilter from "./containers/app/tiktokGiveaway/postDetail/ReplyFilter.js";
import TikTokWinners from "./containers/app/tiktokGiveaway/postDetail/Winner.js";
import TikTokCerticate from "./containers/app/tiktokGiveaway/postDetail/CertificteWinners.js";
import FlipCoin from "./containers/app/games/flipCoin/index.js";
import Dice from "./containers/app/games/diceRole/index.js";
import FacebookGiveaway from "./containers/app/facebookGiveaway/home/index.js";
import AllFbPages from "./containers/app/facebookGiveaway/allFbPages/index.js";
import FacebookIndividualPagePosts from "./containers/app/facebookGiveaway/allPosts/index.js";
import FacebookPostDetails from "./containers/app/facebookGiveaway/postDetails/index.js";
import FilterFacebookComments from "./containers/app/facebookGiveaway/filterComments/index.js";
import FacebookWinnersAndSubstitutes from "./containers/app/facebookGiveaway/winnerAndSubstitutes/index.js";
import FacebookGiveawayResult from "./containers/app/facebookGiveaway/result/index.js";
import AboutUs from "./containers/app/about/index.js";
import FacebookGiveawayTest from "./containers/app/facebookTestGiveaway/home/index.js";
import AllFbPagesTest from "./containers/app/facebookTestGiveaway/allFbPages/index.js";
import FacebookIndividualPagePostsTest from "./containers/app/facebookTestGiveaway/allPosts/index.js";
import FacebookPostDetailsTest from "./containers/app/facebookTestGiveaway/postDetails/index.js";
import FilterFacebookCommentsTest from "./containers/app/facebookTestGiveaway/filterComments/index.js";
import FacebookWinnersAndSubstitutesTest from "./containers/app/facebookTestGiveaway/winnerAndSubstitutes/index.js";
import FacebookGiveawayResultTest from "./containers/app/facebookTestGiveaway/result/index.js";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    color: appColor.black,
  },
  button: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

function App() {
  const navigate = useNavigate();
  // useEffect(() => {
  //   let path = window.location.pathname;

  //   if (path == "/dashboard") {
  //   } else if (path == "/shop") {
  //   } else if (path == "/shop?") {
  //   } else if (path == "/shop?cancel") {
  //   } else if (path == "/myAccount") {
  //   } else if (path == "/privacy-policy") {
  //   } else if (path == "/terms-and-conditions") {
  //   } else if (path == "/games/flip-a-coin") {
  //   } else if (path == "/games/roll-dice") {
  //   } else {
  //     navigate("/");
  //   }
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <Routes>
          {/* main */}
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/shop" element={<Shop />} />
          <Route exact path="/myAccount" element={<MyAccount />} />
          <Route exact path="/privacy-policy" element={<Privacy />} />
          <Route exact path="/terms-and-conditions" element={<Terms />} />

          {/* auth */}
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/signup" element={<SignupPage />} />
          <Route exact path="/verifyCode" element={<VerifyCode />} />
          <Route
            exact
            path="/forgotPassword"
            element={<ForgotPasswordPage />}
          />

          {/* youtube giveaway */}
          <Route exact path="/youtube-giveaway" element={<YoutubeGiveAway />} />
          <Route
            exact
            path="/youtube-giveaway/video-details"
            element={<ChannelSection />}
          />
          <Route
            exact
            path="/youtube-giveaway/comment-filters"
            element={<Participants />}
          />
          <Route
            exact
            path="/youtube-giveaway/winners"
            element={<Congratulations />}
          />
          <Route
            exact
            path="/youtube-giveaway/certificate-winners"
            element={<Winners />}
          />
          <Route exact path="/certificate" element={<Certitficate />} />

          {/* twitter giveAway */}
          <Route exact path="/twitter-giveaway" element={<TwitterGiveAway />} />
          <Route
            exact
            path="/twitter-giveaway/tweet-details"
            element={<PostStatus />}
          />
          <Route
            exact
            path="/twitter-giveaway/comment-filters"
            element={<ReplyFilter />}
          />
          <Route
            exact
            path="/twitter-giveaway/winners"
            element={<TwitterWinners />}
          />
          <Route
            exact
            path="/twitter-giveaway/certificate-winners"
            element={<TwitterCerticate />}
          />

          {/* Instagram */}
          <Route
            exact
            path="/instagram-giveaway"
            element={<InstagramGiveaway />}
          />
          <Route
            exact
            path="/instagram-giveaway/all-posts"
            element={<AllUserPosts />}
          />
          <Route
            exact
            path="/instagram-giveaway/post"
            element={<SinglePost />}
          />
          <Route
            exact
            path="/instagram-giveaway/comment-filters"
            element={<FilterInstaComments />}
          />
          <Route
            exact
            path="/instagram-giveaway/post-detail"
            element={<InstaPostDetail />}
          />
          <Route
            exact
            path="/instagram-giveaway/instagram-accounts"
            element={<InstaAccounts />}
          />
          <Route
            exact
            path="/instagram-giveaway/pages-posts"
            element={<PagesPosts />}
          />
          <Route
            exact
            path="/instagram-giveaway/winners"
            element={<InstagramWinners />}
          />
          <Route
            exact
            path="/instagram-giveaway/certificate-winners"
            element={<InstagramCerticate />}
          />

          {/* //tiktok */}
          <Route exact path="/tiktok-giveaway" element={<TikTokGivaway />} />
          <Route
            exact
            path="/tiktok-giveaway/video-details"
            element={<TikTokPostStats />}
          />
          <Route
            exact
            path="/tiktok-giveaway/comment-filters"
            element={<TikTokReplyFilter />}
          />
          <Route
            exact
            path="/tiktok-giveaway/winners"
            element={<TikTokWinners />}
          />
          <Route
            exact
            path="/tiktok-giveaway/certificate-winners"
            element={<TikTokCerticate />}
          />

          {/* Facebook Giveaway */}
          <Route
            exact
            path="/facebook-giveaway"
            element={<FacebookGiveaway />}
          />

          <Route
            exact
            path="/facebook-giveaway/all-pages"
            element={<AllFbPages />}
          />

          <Route
            exact
            path="/facebook-giveaway/all-posts"
            element={<FacebookIndividualPagePosts />}
          />

          <Route
            exact
            path="/facebook-giveaway/post-details"
            element={<FacebookPostDetails />}
          />

          <Route
            exact
            path="/facebook-giveaway/comment-filters"
            element={<FilterFacebookComments />}
          />

          <Route
            exact
            path="/facebook-giveaway/winners"
            element={<FacebookWinnersAndSubstitutes />}
          />

          <Route
            exact
            path="/facebook-giveaway/result"
            element={<FacebookGiveawayResult />}
          />

          <Route
            exact
            path="/facebook-giveaway-test"
            element={<FacebookGiveawayTest />}
          />

          <Route
            exact
            path="/facebook-giveaway/all-pages-test"
            element={<AllFbPagesTest />}
          />

          <Route
            exact
            path="/facebook-giveaway/all-posts-test"
            element={<FacebookIndividualPagePostsTest />}
          />

          <Route
            exact
            path="/facebook-giveaway/post-details-test"
            element={<FacebookPostDetailsTest />}
          />

          <Route
            exact
            path="/facebook-giveaway/comment-filters-test"
            element={<FilterFacebookCommentsTest />}
          />

          <Route
            exact
            path="/facebook-giveaway/winners-test"
            element={<FacebookWinnersAndSubstitutesTest />}
          />

          <Route
            exact
            path="/facebook-giveaway/result-test"
            element={<FacebookGiveawayResultTest />}
          />

          {/* //games */}
          <Route exact path="/games/flip-a-coin" element={<FlipCoin />} />
          <Route exact path="/games/roll-dice" element={<Dice />} />
        </Routes>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
