import { Box, Typography } from "@mui/material";
import React from "react";
import { appIcons } from "../../../assets";
import CustomButton from "../../../components/CustomButton";

function PlansAndPricingCard({ data, openDialog }) {
  return (
    <Box borderRadius={"16px"} border={"1px solid #161616"} overflow={"hidden"}>
      <Box
        bgcolor={data?.cardColor}
        padding={"10px 13px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography
          variant={"h6"}
          fontFamily={"Roboto Flex"}
          fontSize={20}
          fontWeight={700}
        >
          {data?.heading}
        </Typography>
        {/* {data?.amount !== "Comming Soon" ? (
          <Typography
            variant={"h6"}
            fontFamily={"Roboto Flex"}
            fontSize={20}
            fontWeight={700}
          >
            <sup style={{ fontSize: 12, fontWeight: 800 }}>$</sup>
            {data?.amount}
          </Typography>
        ) : ( */}
        <Typography
          variant={"h6"}
          fontFamily={"Roboto Flex"}
          fontSize={20}
          fontWeight={700}
        >
          <sup style={{ fontSize: 12, fontWeight: 800 }}>$</sup>
          {data?.amount}
        </Typography>
      </Box>
      <Box padding={"12px 12px 16px 12px"}>
        <Typography
          variant={"h6"}
          fontFamily={"Roboto Flex"}
          fontSize={13}
          fontWeight={600}
        >
          {data?.innerTitle}
        </Typography>
        {data?.options.map((optionss) => {
          return (
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"5px"}
              marginTop={"13px"}
            >
              <img
                src={
                  optionss.icon == "tick"
                    ? appIcons.tickIcon
                    : appIcons.crossIcon
                }
                width={"18px"}
              />
              <Typography variant={"body1"} fontSize={12}>
                {optionss?.text}{" "}
                {/* {optionss?.text === "Facebook Giveaways" && (
                  <span
                    style={{
                      fontSize: "10px",
                      fontStyle: "italic",
                      color: "red",
                    }}
                  >
                    comming soon
                  </span>
                )} */}
              </Typography>
            </Box>
          );
        })}
        <Box height={35} width={130} margin={"0px auto"} mt={"20px"}>
          <CustomButton
            btnText={data?.btnText}
            textWeight={500}
            textSize={13}
            handleClickBtn={() => openDialog(data)}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default PlansAndPricingCard;
