import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Stack,
  Grid,
  Button,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { appColor } from "../../../constants/Colors";
import CustomButton from "../../../components/CustomButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { upgradePlan } from "../../../service/common";
import { useContext } from "react";
import { AppContext } from "../../../context";
import { loadStripe } from "@stripe/stripe-js";
import { createStripeSession } from "../../../service/authentiction";
import TextInput from "../../../components/TextInput";
import TextLabel from "../../../components/TextLabel";
import CancelIcon from "@mui/icons-material/Cancel";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  "& .MuiDialog-paper": {
    width: "450px",
    borderRadius: "20px",
    background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: appColor.black }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function UpgradePlanDilaog({
  open,
  handleClose,
  dialogData,
  setIsLoading,
  handleSnackbarVal,
  duration,
  promoData,
  promoValue,
  setPromoValue,
  promoDiscount,
  setPromoDiscount,
  totalPrice,
  setTotalPrice,
}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { setUserData } = useContext(AppContext);
  const [coupon, setCoupon] = useState("");

  const handleUpgradePlan = async () => {
    if (dialogData?.heading == "Free") {
      setIsLoading(true);
      upgradePlan(user?._id, { package: "Free", duration, expiryDate: "" })
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            let data = res?.data?.data;
            setUserData(data);
            localStorage.setItem("user", JSON.stringify(data));
            handleSnackbarVal(true, "success", res?.data?.message);
            handleClose();
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", err?.message);
        });
    } else {
      setIsLoading(false);
      const reffralId = sessionStorage.getItem("referralId");
      let payload = {
        // paymentAmount:
        //   totalPrice > 0
        //     ? parseInt(totalPrice)
        //     : parseInt(dialogData?.packageAmount),
        paymentAmount: parseInt(dialogData?.packageAmount),
        packagName: dialogData?.heading,
        duration,
        reffralId,
        enteredCoupon: coupon.trim(),
      };

      createStripeSession(payload)
        .then((ress) => {
          setIsLoading(false);
          if (ress?.data?.status === "ok") {
            let session = ress;
            window.location.replace(session.data.id);
            handleClose();
          } else {
            handleSnackbarVal(true, "error", ress?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          handleSnackbarVal(true, "error", err?.message);
        });
    }
  };

  // const handleApplyPromo = () => {
  //   if (promoData?.promoCode === promoValue) {
  //     const discount = Number(promoData?.promoDiscount);
  //     const subtotal = Number(dialogData?.packageAmount);
  //     const totalDiscount = (subtotal / 100) * discount;
  //     const subtract = subtotal - Math.round(totalDiscount);
  //     const totalAfterDiscount = Math.round(subtract);
  //     setPromoDiscount(totalDiscount);
  //     setTotalPrice(totalAfterDiscount);
  //     handleSnackbarVal(true, "success", "Promo applied successfully");
  //   } else {
  //     handleSnackbarVal(true, "error", "Invalid promo code");
  //   }
  // };

  const handleApplyPromo = () => {
    if (promoData?.promoCode === promoValue) {
      const discountPercentage = Number(promoData?.promoDiscount);
      const subtotal = Number(dialogData?.packageAmount);

      // Calculate the discount amount
      const discountAmount = (subtotal * discountPercentage) / 100;

      // Subtract the discount amount from the subtotal and round down to the nearest whole number
      const totalAfterDiscount = Math.round(subtotal - discountAmount);
      const roundedDiscountAmount = Math.floor(discountAmount);

      setPromoDiscount(roundedDiscountAmount);
      setTotalPrice(totalAfterDiscount);

      handleSnackbarVal(true, "success", "Promo applied successfully");
    } else {
      handleSnackbarVal(true, "error", "Invalid promo code");
    }
  };

  const handleRemovePromo = () => {
    setPromoValue("");
    setPromoDiscount(0);
    setTotalPrice(0);
    // handleSnackbarVal(true, "success", "Promo removed successfully");
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        scroll="body"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            variant={"h6"}
            fontWeight={"800"}
            fontFamily={"Roboto Flex"}
            textAlign={"center"}
            fontSize={25}
            mt={1}
          >
            Upgrade Your Plan!
            {/* {dialogData?.dialogTitle} */}
          </Typography>
          <Typography
            variant={"body1"}
            fontSize={14}
            fontWeight={400}
            textAlign={"center"}
            fontFamily={"Roboto Flex"}
          >
            Are you sure you want to upgrade your plan?
          </Typography>
        </BootstrapDialogTitle>
        {dialogData?.heading != "Free" && (
          <DialogContent style={{ padding: "10px 20px" }} dividers>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography
                variant={"h2"}
                fontFamily={"Roboto Flex"}
                fontSize={22}
                fontWeight={700}
              >
                {dialogData?.heading}
              </Typography>
              <Typography
                variant={"h2"}
                fontFamily={"Roboto Flex"}
                fontSize={22}
                fontWeight={700}
              >
                <sup style={{ fontSize: 12, fontWeight: 800 }}>$</sup>
                {dialogData?.amount}
              </Typography>
            </Stack>

            <Grid container spacing={2} alignItems={"flex-end"} mt={"5px"}>
              <Grid item xs={12}>
                <TextLabel inputText={"Enter coupon code"} />
                <TextInput
                  inputType={"text"}
                  placeholder={"Enter Coupon Code"}
                  inputVal={coupon}
                  handleChangeInput={(e) => setCoupon(e.target.value)}
                  {...(coupon && {
                    endIcon: (
                      <CancelIcon
                        sx={{ mr: "5px", cursor: "pointer" }}
                        onClick={() => setCoupon("")}
                      />
                    ),
                  })}
                />
              </Grid>
              {/* <Grid item xs={12} md={4}>
                <Button
                  disabled={promoValue && promoData?.isAvailable ? false : true}
                  variant="outlined"
                  sx={{ width: "100%", padding: "7px 15px" }}
                  onClick={handleApplyPromo}
                >
                  Apply
                </Button>
              </Grid> */}
            </Grid>

            <Stack mt={"10px"} alignItems={"flex-end"}>
              {/* <Typography variant="h6" fontSize={16} fontWeight={"500"}>
                SubTotal : $ {dialogData?.packageAmount}
              </Typography>
              <Typography variant="h6" fontSize={16} fontWeight={"500"}>
                Discount : $ {promoDiscount}
              </Typography>
              <Typography variant="h6" fontSize={16} fontWeight={"500"}>
                Total : ${" "}
                {totalPrice > 0 ? totalPrice : dialogData?.packageAmount}
              </Typography> */}
            </Stack>
          </DialogContent>
        )}

        <DialogActions>
          <Box width={"160px"} height={40}>
            <CustomButton
              btnText={dialogData?.heading == "Free" ? "upgrade" : "Buy plan"}
              handleClickBtn={handleUpgradePlan}
            />
          </Box>
          {/* <Box
            width={"160px"} 
            height={40}
            border={"1px solid"}
            borderRadius={"50px"}
          >
            <CustomButton
              btnText={"Cancel"}
              btnBackground={"transparent"}
              textColor={appColor.black}
              handleClickBtn={handleClose}
            />
          </Box> */}
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}